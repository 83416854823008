// Images paths
$login-background-image-path: '../../../image/login_image.png';
$dashboard-dusseldorf-image-path: '../../../image/dusseldorf.png';

// Defaults
$default-spacing: 15px;
$default-max-app-width: 450px;
$default-max-app-height: 930px;

// Colors
$color-white: #fff;

// Yellow
$color-light-yellow: #FFBF58;
$color-yellow: #FFAC25;
$color-dark-yellow: #E29312;

// Green
$color-green: #2AC769;

// Blue
$color-blue: #78C3FE;
$color-darker-blue: #5BACEC;

// Purple
$color-light-purple: #9B8DC7;
$color-purple: #5236AB;
$color-bright-purple: #7059B7;
$color-dark-purple: #402692;

// Red
$color-light-red: #FF5858;
$color-red: #E31937;
$color-bright-red: #D2062B;
$color-dark-red: #D2062B;

// Magenta
$color-light-magenta: #CB7CA3;
$color-magenta: #A82465;
$color-dark-magenta: #991F3D;

// Grey
$color-light-grey: #EAE8F0;
$color-silver-grey: #E6E6E6;
$color-medium-grey: #9A9A9A; // checkInQuestionnaire-hr
$color-dark-silver: #BDBDC2;
$color-light-silver: #E1E1E1; // Default texts, text in text field
$color-silver-sand: #C4C4C4; // Dividers & borders, disabled states
$color-dark-sand: #5a5959; // Dark Dividers and Borders
$color-payne-grey: #3F414E; // Inactive states
$color-gray: #333; // Secondary text
$color-black: #000; // Titles, texts

// Gradient for headings
$color-gradient-first: #DB093B;
$color-gradient-second: #A22064;
$color-gradient-third: #5135A8;
$purple-gradient-list: $color-gradient-first, $color-gradient-second, $color-gradient-third;

// Gradient for cards
$color-grey-gradient-first: #EBEBEB;
$color-grey-gradient-second: #FCFCFC;
$gray-gradient-list: $color-grey-gradient-first, $color-grey-gradient-second;

// Font weights
$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-medium: 600;
$font-weight-normal: 400;
$font-weight-light: 300;

// Fonts sizes
$font-size-xlg: 24px;
$font-size-mlg: 20px;
$font-size-lg: 16px;
$font-size-md: 14px;
$font-size-sm: 12px;
$font-size-xsm: 10px;

// Line heights
$line-height-xlg: 30px;
$line-height-lg: 22px;
$line-height-md: 18px;
$line-height-sm: 16px;
$line-height-xsm: 14px;
$line-height-supersm: 11px;

// Letter spacing
$letter-spacing-xlg: 4px;
$letter-spacing-lg: 1.5px;
$letter-spacing-md: .5px;

// Font family
$body-font-family: 'Roboto', sans-serif;

// Office swithcer
$background-color-transparent-white: #ffffffb0;

// $breakpoints: 
$breakpoint-xxs: 280px;
$breakpoint-xs:  320px;
$breakpoint-sm:  360px;
$breakpoint-md:  375px;
$breakpoint-lg:  410px;
$breakpoint-xlg: 450px; 

//breakpoint height

$breakpoint-height-xs: 580px;
$breakpoint-height-sm: 670px
