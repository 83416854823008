$color-main-theme: var(--color-main-theme);
$color-secondary-theme: var(--color-secondary-theme);

$background-color-main: var(--background-color-main);
$background-color-progress-bar: var(--background-color-progress-bar);
$background-color-office-switcher: var(--background-color-office-switcher);

$color-checkbox-background: var(--color-checkbox-background);
$color-checkbox: var(--color-checkbox);
$color-checkbox-focus: var(--color-checkbox-focus);
$color-checkbox-disabled: var(--color-checkbox-disabled);
$color-checkbox-checked-disabled: var(--color-checkbox-checked-disabled);
$color-checkbox-border: var(--color-checkbox-border);

$color-bottom-entity-info-card: var(--color-bottom-entity-info-card);
$color-bottom-entity-info-description: var(--color-bottom-entity-info-description);
$color-status-active: var(--color-status-active);
$color-status-whole-day: var(--color-status-whole-day);
$color-status-flexible: var(--color-status-flexible);
$color-status-blocked: var(--color-status-blocked);
$color-status-hotDesk: var(--color-status-hotDesk);

$color-error: var(--color-error);
$color-warning: var(--color-warning);
$color-success: var(--color-success);
$color-info: var(--color-info);
$color-disabled-state: var(--color-disabled-state);

$color-default-icon: var(--color-default-icon);

$color-text: var(--color-text);
$color-secondary-text: var(--color-secondary-text);
$color-text-main: var(--color-text-main);
$color-text-special: var(--color-text-special);

$color-button-primary: var(--color-button-primary);
$color-button-primary-border: var(--color-button-primary-border);
$color-button-primary-active: var(--color-button-primary-active);
$color-button-primary-hover: var(--color-button-primary-hover);
$color-button-primary-disabled: var(--color-button-primary-disabled);
$color-text-primary-button: var(--color-text-primary-button);

$color-text-secondary-button: var(--color-text-secondary-button);
$color-button-secondary: var(--color-button-secondary);
$color-button-secondary-border: var(--color-button-secondary-border);
$color-button-secondary-active: var(--color-button-secondary-active);
$color-button-secondary-hover: var(--color-button-secondary-hover);
$color-button-secondary-disabled: var(--color-button-secondary-disabled);

//Button Third
$color-button-third-border: var(--color-button-third-border);
$color-button-third-enabled: var(--color-button-third-enabled);

//HR tags
$color-background-hr: var(--color-background-hr);
$color-questionnaire-hr: var(--color-questionnaire-hr);

//Statistics
$color-background-stats-header: var(--color-background-stats-header);

//Map
$color-background-map: var(--color-background-map);
$color-status-free: var(--color-status-free);
$color-status-fully-booked: var(--color-status-fully-booked);
$color-status-partially-booked: var(--color-status-partially-booked);
$color-status-blocked: var(--color-status-blocked);

//Your Booking Card
$color-input-reservation-detail: var(--color-input-reservation-detail);

//Date Time Picker
$color-border-input-datetimepicker: var(--color-border-input-datetimepicker);

//Badge Card
$color-background-profile-photo: var(--color-background-profile-photo);

//Booking Card
$color-background-booking-info: var(--color-background-booking-info);

//Error
$color-error: var(--color-error);

@import './purpletheme';