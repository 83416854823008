.app-root {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  margin-top: 5%;

  .logo {
    display: block;
    margin: 5%;
    width: 40%;
    margin-bottom: 10%;
  }

  .mainPage-description {
    font-size: 16px;
    text-align: left;
    justify-content: center;
    margin-right: 7%;
    margin-left: 7%;
  }

  .covid-info {
    align-self: flex-start;
    margin: 3% 7%;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    padding: 10%;
    width: 100%;
  }
  
  .btn-secondary {
    color: $color-text-secondary-button;
    background-color: $color-button-secondary;
    border-radius: 32px;
    border-color: $color-button-secondary-border;
    outline-color: transparent;
    padding: 10px 20px;
    border-style: solid;
    font-weight: 600;
    margin: 5px;

    &:active {
      background-color: $color-button-secondary-active;
      border-color: $color-button-secondary-active;
      color: $color-button-secondary;
    }

    &:hover {
      background-color: $color-button-secondary-hover;
      border-color: $color-button-secondary-hover;
      color: $color-button-secondary;
    }

    &:disabled {
      border-color: $color-button-secondary-disabled;
      color: $color-button-secondary-disabled;
    }
  }
  .btn-secondary.button {
    color: $color-magenta;
    border-color: $color-magenta;
  }
  .btn-secondary.button:hover {
    background: $color-magenta;
    color: $color-white;

    .icon {
      color: $color-white;
    }
  }

  .fileName{
    font-size: 14px;
  }

  .main-button-send {
    margin: 5% $default-spacing;
    text-align: center;
    width: 50%;

    .heading {
      margin: 0;
    }
    .buttonSend {
      width: 100%;
      max-width: 450px;
    }
  }

  .hidden{
    display: none;
  }
}

.btn-primary {
  color: $color-text-primary-button;
  background-color: $color-button-primary;
  border-color: $color-button-primary-border;
  border-radius: 32px;
  outline-color: transparent;
  padding: 10px 20px;
  border-style: solid;
  font-weight: 600;

  &:active {
    background-color: $color-button-primary-active;
    border-color: $color-button-primary-active;
  }

  &:hover {
    background-color: $color-button-primary-hover;
    border-color: $color-button-primary-hover;
  }

  &:disabled {
    background-color: $color-button-primary-disabled;
    border-color: $color-button-primary-disabled;
    opacity: 0.5;
  }
}

.fileName{
  border-radius: 10px;
  background: $color-light-grey;
  padding: 16px;
  width: 80%;  
  margin: 10px auto;
  margin-top: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  .report-problem-close-icon{
    margin: auto 5px;
    font-size: 24px;
  }
}