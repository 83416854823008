.circle-check{
    color: $color-green;
    font-size: 70px;
}

.successPage-description {
  font-size: 20px;
  height: 10%;
  text-align: center;
  justify-content: center;
  margin-right: 7%;
  margin-left: 7%;
  margin-top: 10%;
}

.main-button-ok {
  margin: 10% $default-spacing;
  text-align: center;
  width: 50%;
  .buttonOk {
    width: 100%;
    max-width: 450px;
    background-color: $color-green;
    border-color: $color-green;
  }
  .buttonOk:hover {
    width: 100%;
    max-width: 450px;
    background-color: $color-green;
    border-color: $color-green;
  }
}
