.qr-reader-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    background-position: center;
    background-attachment: fixed;
    color: $color-white;
    font-size: 18px;
    text-align: center;
    position: absolute;
    z-index: 6;

    p {
        color: $color-text;
        margin: $default-spacing;
    }
    .qr-reader-view{
        section{
            border-radius: 25%;
        }
    }

    .buttonSend{
        width: 100%;
        max-width: 450px;
    }

    .info-icons-wrapper {
        margin-top: $default-spacing;
        margin-bottom: 15%;
        display: flex;
        justify-content: center;
        align-items: baseline;

        .icon-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 $default-spacing;
            
            .icon {
                font-size: 40px;
            }

            p {
                margin:0;
                font-size: $font-size-sm;
            }
        }
    }
}

.desk-qr-reader-button-wrapper {
    margin: 40px 0;
}