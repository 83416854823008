.times-circle{
    margin-top: 15%;
    color: $color-error;
    font-size: 70px;
}

.errorPage-description{
    font-size: 20px;
    height: 10%;
    text-align: center;
    justify-content: center;
    margin-right: 7%;
    margin-left: 7%;
}

.buttonBack{
    width: 100%;
    max-width: 450px;
    background-color: $color-button-primary;
    border-color: $color-button-primary-border;
}
.buttonBack:hover {
    width: 100%;
    max-width: 450px;
    background-color: $color-button-primary;
    border-color: $color-button-primary-border;
  }