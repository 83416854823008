body {
    --color-main-theme: #{$color-purple};
    --color-secondary-theme: #{$color-light-purple};

    --background-color-main: #{$color-white};
    --background-color-progress-bar: #{$color-purple};

    // Checkbox colors
    --color-checkbox-background: #{$color-white};
    --color-checkbox: #{$color-purple};
    --color-checkbox-focus: #{$color-dark-purple};
    --color-checkbox-disabled: #{$color-light-silver};
    --color-checkbox-checked-disabled: #{$color-silver-sand};
    --color-checkbox-border: #{$color-gray};

    // Statuses
    --color-bottom-entity-info-card: #{$color-light-grey};
    --color-bottom-entity-info-description: #{$color-light-yellow};
    --color-status-active: #{$color-green};
    --color-status-whole-day: #{$color-light-magenta};
    --color-status-flexible: #{$color-light-yellow};
    --color-status-blocked: #{$color-gray};
    --color-status-hotDesk: #{$color-darker-blue};

    // Messages
    --color-error: #{$color-red};
    --color-warning: #{$color-yellow};
    --color-success: #{$color-green};
    --color-info: #{$color-blue};
    --color-disabled-state: #{$color-silver-sand};

    // Icons
    --color-default-icon: #{$color-magenta};

    // Texts
    --color-text: #{$color-black};
    --color-secondary-text: #{$color-gray};
    --color-text-main: #{$color-purple};
    --color-text-special: #{$color-magenta};

    //Button Primary
    --color-button-primary: #{$color-purple};
    --color-button-primary-border: #{$color-purple};
    --color-button-primary-active: #{$color-dark-purple};
    --color-button-primary-hover: #{$color-bright-purple};
    --color-button-primary-disabled: #{$color-light-purple};
    --color-text-primary-button: #{$color-white};

    //Button Secondary
    --color-text-secondary-button: #{$color-purple};
    --color-button-secondary: #{$color-white};
    --color-button-secondary-border: #{$color-purple};
    --color-button-secondary-active: #{$color-dark-purple};
    --color-button-secondary-hover: #{$color-purple};
    --color-button-secondary-disabled: #{$color-silver-sand};

    //Button Third
    --color-button-third-border: #{$color-magenta};
    --color-button-third-enabled: #{$color-white};

    //HR tags
    --color-background-hr: #{$color-light-silver};
    --color-questionnaire-hr: #{$color-medium-grey};

    //Statistics
    --color-background-stats-header: #{$color-light-silver};

    //Map
    --color-background-map: #{$color-light-silver};
    --color-status-free: #{#90ee90};
    --color-status-fully-booked: #{red};
    --color-status-partially-booked: #{orange};
    --color-status-blocked: #{$color-light-silver};

    //Your Booking Card
    --color-input-reservation-detail: #{$color-light-silver};

    //Date Time Picker
    --color-border-input-datetimepicker: #{$color-dark-sand};

    //Badge Card
    --color-background-profile-photo: #{$color-gray};

    //Booking Card
    --color-background-booking-info: #{$color-light-silver};

    // Error
    --color-error: #{$color-red};

    // Office switcher
    --background-color-office-switcher: #{$background-color-transparent-white};
}